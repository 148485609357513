import { Component } from "react"
import { navigate } from "gatsby"

class ReloadPage extends Component {
    
    componentDidMount = async() => {
        navigate("/vehicleInfo/?r=1"+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
    }
    render() {
        return ('');
    }
}

export default ReloadPage;
